<template>
  <div>
    <!-- Navbar -->
    <nav class="navbar navbar-color" role="navigation" aria-label="main navigation">
        <div class="container">
            <div class="navbar-brand">
          <router-link to="/" class="navbar-item">
                <img src="../../../public/logo.png" width="50" height="50">
                <h1 class="heading">Square<span>Garden</span></h1>
            </router-link>
          <span class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarHomepage" @click="toggleNavmenu">
            <span></span>
            <span></span>
            <span></span>
          </span>
        </div>
        <div id="navbarHomepage" class="navbar-menu" :class="mobile">
            <div class="navbar-start">
                <div class="navbar-item">
                    <button id="installbutton" class="button is-success is-inverted install-button hide"><i class="fas fa-download"></i>installieren</button>
                    <span id="iOSInstall" class="hide">To install press <img src="../../../public/img/add-to-homescreen.png" alt="add-to-home-screen" class=ios> and add to Homescreen</span>
                </div>
            </div>
          <div class="navbar-end">
            <div class="navbar-item">
                <span class="link"><router-link to="/product" style="color: black;">{{ $t("nav.product.value")}}</router-link></span>
            </div>
            <div class="navbar-item">
                <span class="link"><router-link to="/contact" style="color: black;">{{ $t("nav.contact.value")}}</router-link></span>
            </div>
            <div class="navbar-item">
                <div class="buttons">
                    <router-link to="/login" class="button is-success is-inverted"><i class="far fa-user"></i>Login</router-link>
                    <router-link to="/register" class="button is-success is-inverted"><i class="far fa-envelope-open"></i>{{ $t("nav.register.value")}}</router-link>
                    <languageSwitcher />
                </div>
            </div>
          </div>
        </div>
       </div>
    </nav>
    <div class="container">
        <section>

            <h1 class="contact-heading">{{ $t("contact.productHeading.value")}}</h1>

            <div class="card boxed first">
                <div class="element">
                    <h2 class="heading">{{ $t("contact.aboutCard.heading")}}</h2>
                    <p class="text"> 
                        {{ $t("contact.aboutCard.text")}}
                    </p>
                </div>
                <img src="../../assets/contact_pictures/contact-picture.jpg" alt="butterfly-and-flower" class="element">
            </div>

            <div class="card second">
                <h2 class="heading">{{ $t("contact.contactCard.heading")}}</h2>
                <div class="boxed-form">
                    <img src="../../assets/contact_pictures/ideas.jpg" alt="" class="element">
                    <div class="">
                        <form action="">
                            <label for="email" class="label">{{ $t("contact.form.email.value")}}</label>
                            <div class="control has-icons-left">
                                <span class="icon is-small is-left">
                                    <i class="far fa-envelope"></i>
                                </span>
                                <input id="email" type="email" v-model="email" class="input" required />
                                <p class="help is-danger">{{ email_error }}</p>
                            </div>
                            <label for="email" class="label">{{ $t("contact.form.message.value")}}</label>
                            <div class="control has-icons-left">
                                <span class="icon is-small is-left">
                                    <i class="far fa-comment"></i>
                                </span>
                                <textarea id="text" v-model="message" class="textarea" placeholder="" required />
                                <p class="help is-danger">{{ message_error }}</p>
                            </div>
                            <button class="button is-success save-button" type="submit" @click.prevent.self="sendMessage">{{ $t("contact.send.value")}} <i class="far fa-paper-plane"></i></button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        <div class="foot"></div>
    </div>
  </div>
</template>

<script>
import languageSwitcher from "@/components/multilang/languageSwitcher";

export default {
    name: "Product",
    components: {
        languageSwitcher
    },
    data() {
        return {
            mobile: "",
            email: "",
            message: "",
            email_error: "",
            message_error: "",
        }
    },
    methods: {
        //UI Methods
        toggleNavmenu(e) {
            //Toggling the burger-menu
            if(!e.target.classList.contains("is-active")){
                e.target.classList.add("is-active")
                this.mobile="is-active";
            } else {
                e.target.classList.remove("is-active");
                this.mobile="";
            }
        },
        triggerSuccessAlert(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-success',
                duration: 3500,
            })
        },
        triggerFailAlert(message) {
            this.$buefy.toast.open({
                message: message,
                type: 'is-danger',
                duration: 3500,
            })
        },
        sendMessage() {
            const validateEmail = this.validateEmail();
            const validateMessage = this.validateMessage();
            const BASE_URL = "https://squaregarden-backend.sharky.live/api/v1/message";
            const devURL = "http://localhost:24711/api/v1/message"
            const data = {email: this.email, message: this.message};
            const options = {
                method: "POST",
                body: JSON.stringify(data),
                headers: {
                    "Content-type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                }
            }
            
            //Checking for errors
            if(!validateEmail) {
                this.email_error ="This is not a valid email";
            } 
            if (!validateMessage) {
                this.message_error = "Your Message can not be empty";
            }
            if(validateEmail) {
                this.email_error ="";
            } 
            if (validateMessage) {
                this.message_error = "";
            }    
            if(validateEmail && validateMessage){
                fetch(devURL, options)
                    .then( response => {
                        console.log(response)
                        if(response.status == 200 || response.status == 201 || response.status == 204) {
                            this.triggerSuccessAlert("Thank You for sending us a message!")
                        } else if(response.status == 429) {
                            this.triggerFailAlert("You send to many messages today! Please try again tomorrow")
                        } else if(response.status == 401) {
                            this.triggerFailAlert("This is no valid Email!");
                        }
                        else {
                            this.triggerFailAlert("There accured an Error sending your message!")
                        }
                    })
                    .catch(() => this.triggerFailAlert("There accured an Error sending your message!"))

                this.message = "";
                this.email = "";
            }
        },
        validateMessage() {
            if(this.message == "") {
                return false;
            } else {
                return true;
            }
        },
        validateEmail() {
            const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return res.test(String(this.email).toLowerCase());
        }
    }
}
</script>

<style>
.navbar-color {
    background: #94C973;
}

img {
    max-width: 400px;
    max-height: 400px;
    border-radius: 50%;
    padding: 5px 0;
}

img.ios {
    width: 20px;
    height: 20px;
    border-radius: 2px;
}

.element {
    display:block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    align-items: center;
}

h1.heading {
    text-transform: uppercase;
    color: #107869;
    font-size: 18px;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
}

h1.contact-heading {
    color: #107869;
    font-size: 2.2rem;
    margin-top: 8px;
    margin-left: 16px;
    font-weight: 600;
    margin-bottom: 50px;
}

h2.heading {
    font-size: 1.8rem;
    font-weight: 500;
    margin-left: 0.1em;
    margin-bottom: 1em;
    text-align: center;
    margin-top: 1em;
}

.text {
    margin: 0 0.3em;
}

.card {
    max-width: 1200px;
    padding: 5px;
}

.first {
    margin: 10px 5px;
}

.second {
    margin-top: 10px;
    margin-left: 5px;
    margin-right: 5px;
}

.save-button {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
    margin-bottom: 10px;
}

.link{
    font-size: 1.1rem;
    font-weight: 500;
}

i{
    margin-right: 5px;
}

.hide {
  visibility: hidden;
}

.foot {
    padding-bottom: 20px;
}

@media screen and (min-width: 600px) {
    .boxed {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
        text-align: center;
    }
}

@media screen and (min-width: 800px) {
      .boxed-form {
        display: grid;
        grid-template-columns: 1fr 2fr;
        align-items: center;
        text-align: center;
    }  
}
</style>